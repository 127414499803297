import { styled } from '@mui/material/styles';

export const StyledComponents = {
  Footer: styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.navy.main,
    color: theme.palette.white.main,
    height: '100%',
    paddingTop: 22,

    '& address': {
      fontFamily: 'Open Sans',
      fontSize: '0.9375rem',
      fontWeight: 400,
    },
  })),
  Root: styled('div')(({ theme }) => ({
    display: 'flex',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(6),
  })),
  SocialIconsGroup: styled('div')({
    display: 'flex',
  }),
  IconWrapper: styled('div')({
    marginRight: 22,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
};
