import { styled } from '@mui/material/styles';
import { AppBar, Typography, AppBarProps, Container, ContainerProps, Button } from '@mui/material';

import THEME_COLORS, { ThemeColorMap } from 'constants/themeColors';
import { ButtonProps } from '@mui/material';

const lightBlue = ThemeColorMap.get('light-blue');

const headerHeight = {
  default: 82,
  md: 72,
  sm: 60,
};

interface StyledAppBarProps extends AppBarProps {
  navBottomBorder?: boolean;
  onTop: boolean;
  blendColor: string;
  customColor: string;
}

interface MenuItemProps {
  withArrow: boolean;
}

export const StyledComponents = {
  HeaderWrapper: styled('div')(({ theme }) => ({
    position: 'relative',
    zIndex: 10000,
    height: headerHeight.default,
    [theme.breakpoints.down('md')]: {
      height: headerHeight.md,
    },
    [theme.breakpoints.down('sm')]: {
      height: headerHeight.sm,
    },
  })),
  Button: styled(Button)<ButtonProps>(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px !important',
    },
  })),
  Appbar: styled(AppBar, {
    shouldForwardProp: (prop) => !['onTop', 'blendColor', 'customColor'].includes(prop as string),
  })<StyledAppBarProps>(({ navBottomBorder, onTop, blendColor, customColor }) => ({
    display: 'flex',
    marginBottom: 0,
    transition: 'all 0.2s linear',
    backgroundColor: onTop && blendColor ? 'transparent !important' : blendColor ? blendColor : customColor,
    boxShadow: onTop && blendColor ? 'none !important' : undefined,
    borderBottomStyle: 'solid',
    borderBottomWidth: navBottomBorder ? 1 : 0,
    borderBottomColor: `${lightBlue}40`,
    height: 'inherit',
  })),
  Container: styled(Container)<ContainerProps>(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 'inherit',
    gap: 40,
    [theme.breakpoints.down(360)]: {
      padding: '0px 8px !important',
    },
  })),
  Logo: styled('div')(({ theme }) => ({
    height: 40,
    display: 'flex',
    color: 'black',
    alignItems: 'center',
    justifyContent: 'left',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: '24px',
      justifyContent: 'center',
    },

    '& img': {
      height: '40px',
      [theme.breakpoints.down('sm')]: {
        height: '24px',
      },
    },
  })),

  Menu: styled('ul')({
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    gap: 25,

    '& > li': {
      position: 'relative',
      listStyle: 'none',
      cursor: 'pointer',
      borderBottom: '2px solid transparent',
      transition: 'all 0.2s linear',
      overflow: 'hidden',
      paddingBottom: 2,

      '&:hover': {
        borderBottom: `2px solid ${THEME_COLORS.SECONDARY_1}`,
        overflow: 'visible',
      },
      // '&:hover $subMenuWrapper': { opacity: 1 },
    },
  }),
  NavItemText: styled(Typography)({
    textTransform: 'uppercase',
    fontFamily: 'Montserrat',
  }),
  MenuItem: styled('div')<MenuItemProps>(({ withArrow }) => ({
    display: 'flex',
    alignItems: withArrow ? 'center' : 'initial',
    position: withArrow ? 'relative' : 'initial',
    paddingRight: withArrow ? '1.5rem' : 'initial',

    '& > svg': {
      position: 'absolute',
      right: 0,
      top: 0,
      marginTop: -3,
    },
  })),
  SubMenuWrapper: styled('div')({
    position: 'absolute',
    width: 200,
    top: '100%',
    right: 'auto',
    opacity: 1,
    paddingTop: '10%',

    '& > ul': {
      padding: 0,

      '& > li': {
        listStyle: 'none',
        border: '1px solid ' + THEME_COLORS.SECONDARY_1,
        backgroundColor: THEME_COLORS.PRIMARY_2 + 'E6',
        padding: '6px 8px',
        margin: '8px 0',
        borderRadius: 12,
        '&:hover': {
          backgroundColor: THEME_COLORS.ACTION_1,
        },
      },
    },
  }),
  SubMenuItemText: styled(Typography)({
    textTransform: 'uppercase',
    fontFamily: 'Montserrat',
    fontSize: 14,
  }),
};
