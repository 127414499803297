import { ReactElement } from 'react';

import { StyledComponents } from './styled';

export interface ISpacerProps {
  width?: string;
  space?: string;
}

/**
 * Renders Spacer
 *
 * @param {ISpacerProps} props
 * @return {ReactElement}
 */
export default function Spacer({ width = '100%', space = '20px' }: ISpacerProps): ReactElement {
  return (
    <StyledComponents.Spacer
      width={width}
      space={space}
    ></StyledComponents.Spacer>
  );
}
