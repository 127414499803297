import { styled } from '@mui/material/styles';

interface StyledSpacerProps {
  width: string;
  space: string;
}

export const StyledComponents = {
  Spacer: styled('div', {
    shouldForwardProp: (prop) => !['width', 'space'].includes(prop as string),
  })<StyledSpacerProps>(({ width, space }) => ({
    width: width,
    height: space,
  })),
};
