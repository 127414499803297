enum THEME_COLORS {
  'PRIMARY_1' = '#dd004d',
  'PRIMARY_1_NAV' = '#dd004d',
  'PRIMARY_2' = '#2d2c5c',
  'PRIMARY_3' = '#e5950b',
  'SECONDARY_1' = '#dbf4fc',
  'SECONDARY_2' = '#c1c0e1',
  'ACTION_1' = '#0099cc',
  'ACTION_2' = '#77049d',
}

export const ThemeColorMap = new Map<string, string>();
ThemeColorMap.set('pink', '#DD004D');
ThemeColorMap.set('navy', '#2d2c5c');
ThemeColorMap.set('yellow', '#e5950b');
ThemeColorMap.set('light-blue', '#dbf4fc');
ThemeColorMap.set('lavender', '#c1c0e1');
ThemeColorMap.set('blue', '#0099cc');
ThemeColorMap.set('purple', '#77049d');
ThemeColorMap.set('white', '#ffffff');
ThemeColorMap.set('black', 'black');
ThemeColorMap.set('alice-blue', '#edfafe');
ThemeColorMap.set('whitesmoke', '#f7f7f8');

export default THEME_COLORS;
