export const UNICODE = Object.freeze({
  CURRENCY_HTML_SYMBOL: '\u20B9', // Refers to Rupee symbol (HTML entity)
});

export enum AspirantProfileAgeGroup {
  FIVE_TO_SEVEN = '5-7',
  EIGHT_TO_ELEVEN = '8-11',
  TWELVE_TO_FOURTEEN = '12-14',
  GREATER_THAN_FIFTEEN = '>15',
}

export enum ProductAgeGroup {
  FIVE_TO_SEVEN = '5-7',
  EIGHT_TO_FOURTEEN = '8-14',
  GREATER_THAN_FIFTEEN = '>15',
}

export enum CounsellingSessionType {
  PERSONALITY_INSIGHTS = 'PERSONALITY_INSIGHTS',
  CAREER_COUNSELLING_INITIAL = 'CAREER_COUNSELLING_INITIAL',
  CAREER_COUNSELLING_FINAL = 'CAREER_COUNSELLING_FINAL',
  GENLITE_SESSION = 'GENLITE_SESSION',
}

export const CMSAllowedImageTypes = ['jpeg', 'jpg', 'png', 'svg'];
