import { ReactElement, useState, useEffect, Fragment } from 'react';
import Link from 'next/link';
import { Input } from '@builder.io/sdk/dist';
import {
  useMediaQuery,
  useTheme,
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  Drawer,
} from '@mui/material';
import { Close, ExpandLess, ExpandMore, KeyboardArrowDown, Menu } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';
import { useScrollYPosition } from 'react-use-scroll-position';

import AuthLinks from './AuthLinks';
import THEME_COLORS from 'constants/themeColors';
import { StyledComponents } from './styled';
import { APP_ROUTES } from 'constants/routes';

enum LinkVisibility {
  ALL = 'all',
  LOGGED_IN_ONLY = 'logged-in-only',
  NON_LOGGED_IN_ONLY = 'non-logged-in-only',
}

type TLinkVisibility = LinkVisibility.ALL | LinkVisibility.LOGGED_IN_ONLY | LinkVisibility.NON_LOGGED_IN_ONLY;

interface Item {
  name: string;
  link: string;
  visibility?: TLinkVisibility;
}

export interface IItemWithSubMenu {
  name: string;
  link: string;
  child?: Item[];
  visibility?: TLinkVisibility;
}

export interface NavbarProps {
  menuItems?: IItemWithSubMenu[];
  color?: keyof Record<THEME_COLORS, string>;
  blendMode?: boolean;
  navBottomBorder?: boolean;
  /**
   * Enable or disable elevation of Navbar.
   */
  disableElevation?: boolean;
}

export const NavbarProps: Input[] = [
  {
    name: 'menuItems',
    type: 'string',
  },
  { name: 'color', type: 'string' },
  { name: 'blendMode', type: 'boolean' },
  { name: 'navBottomBorder', type: 'boolean' },
];

const defaultMenuList: IItemWithSubMenu[] = [];

const checkItemVisibility = (itemVisibility: IItemWithSubMenu['visibility'], authState: boolean) => {
  if (itemVisibility === LinkVisibility.LOGGED_IN_ONLY && !authState) {
    return false;
  }
  if (itemVisibility === LinkVisibility.NON_LOGGED_IN_ONLY && authState) {
    return false;
  }
  return true;
};

/**
 * Navbar component
 *
 * @param {NavbarProps} props
 * @return {ReactElement}
 */
export default function Navbar({
  menuItems = defaultMenuList,
  color = THEME_COLORS.PRIMARY_2,
  disableElevation = false,
  blendMode,
  navBottomBorder = false,
}: NavbarProps): ReactElement {
  const theme = useTheme();
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [openedSubMenu, setOpenedSubMenu] = useState<Number | null>(null);
  const scrollY = useScrollYPosition();
  const isLoggedIn = !isLoading && isAuthenticated;

  let blendColor;

  if (blendMode) {
    blendColor = THEME_COLORS.PRIMARY_2;
  }

  useEffect(() => {
    if (!isMobile && isNavOpen) {
      setIsNavOpen(false);
    }
  }, [isMobile]);

  const toggleMenu = (index: number) => setOpenedSubMenu(index === openedSubMenu ? null : index);

  const ListMenu = () => (
    <StyledComponents.Menu>
      {menuItems.map((item, index) => {
        const hasChild = Boolean(item.child?.length);

        if (!checkItemVisibility(item.visibility, isLoggedIn)) return null;

        return (
          <li key={`menu-item-${index}`}>
            <Link
              href={item.link ?? ''}
              passHref
            >
              <StyledComponents.MenuItem withArrow={hasChild}>
                <StyledComponents.NavItemText variant="body1">{item.name}</StyledComponents.NavItemText>
                {hasChild && <KeyboardArrowDown height={12} />}
              </StyledComponents.MenuItem>
            </Link>
            {hasChild ? (
              <StyledComponents.SubMenuWrapper>
                <ul>
                  {item.child?.map((sItem: Item, idx: number) => {
                    if (!checkItemVisibility(sItem.visibility, isLoggedIn)) return null;

                    return (
                      <li key={idx}>
                        <Link
                          href={sItem.link ?? ''}
                          passHref
                        >
                          <StyledComponents.SubMenuItemText variant="body1">
                            {sItem.name}
                          </StyledComponents.SubMenuItemText>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </StyledComponents.SubMenuWrapper>
            ) : null}
          </li>
        );
      })}
    </StyledComponents.Menu>
  );

  interface IDrawerMenuListProps {
    clickHandler: () => void;
  }

  const DrawerMenuList = ({ clickHandler }: IDrawerMenuListProps) => {
    return (
      <Box role="presentation">
        <List>
          {menuItems.map((menuItem, index) => {
            const hasChild = Boolean(menuItem.child?.length);
            if (!checkItemVisibility(menuItem.visibility, isLoggedIn)) return null;
            return (
              <Fragment key={index}>
                <Link
                  href={hasChild ? '#' : menuItem.link ?? ''}
                  passHref
                >
                  <ListItem
                    button
                    key={index}
                    onClick={() => {
                      toggleMenu(index);
                      if (hasChild) return;
                      clickHandler();
                    }}
                  >
                    <ListItemText
                      primary={menuItem.name}
                      primaryTypographyProps={{
                        variant: 'h6',
                        style: {
                          fontWeight: 500,
                          lineHeight: 1.88,
                          letterSpacing: 'normal',
                        },
                      }}
                    />
                    {hasChild && (openedSubMenu === index ? <ExpandLess /> : <ExpandMore />)}
                  </ListItem>
                </Link>
                {hasChild && (
                  <Collapse
                    in={openedSubMenu === index}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      disablePadding
                    >
                      {menuItem.child?.map((subItem, index) => {
                        if (!checkItemVisibility(subItem.visibility, isLoggedIn)) return null;
                        return (
                          <Link
                            href={subItem.link ?? ''}
                            passHref
                            key={index}
                          >
                            <ListItem
                              button
                              style={{ paddingLeft: 30 }}
                              onClick={() => {
                                clickHandler();
                              }}
                              // className={classes.navItem}
                            >
                              <ListItemText
                                primary={'-  ' + subItem.name}
                                primaryTypographyProps={{
                                  variant: 'h6',
                                  style: {
                                    fontWeight: 500,
                                    lineHeight: 1.88,
                                    letterSpacing: 'normal',
                                  },
                                }}
                              />
                            </ListItem>
                          </Link>
                        );
                      })}
                    </List>
                  </Collapse>
                )}
              </Fragment>
            );
          })}
        </List>
      </Box>
    );
  };

  const DrawerMenuIcon = () => (
    <IconButton
      color="inherit"
      aria-label="Drawer toggle"
      edge="end"
      onClick={() => setIsNavOpen(!isNavOpen)}
      size="large"
      style={{ padding: '0px' }}
      data-testid="drawerToggle"
    >
      {isNavOpen ? <Close /> : <Menu />}
    </IconButton>
  );

  const BrandLogo = (
    <StyledComponents.Logo>
      <Link
        href="/"
        passHref
      >
        <a>
          <img
            src={'/images/genleap-logo-white.png'}
            alt="GENLEAP Logo"
          />
        </a>
      </Link>
    </StyledComponents.Logo>
  );

  const NavLinks = () => {
    if (isMobile) return <DrawerMenuIcon />;

    return <ListMenu />;
  };

  return (
    <StyledComponents.HeaderWrapper>
      <StyledComponents.Appbar
        navBottomBorder={navBottomBorder}
        position={'fixed'}
        onTop={scrollY === 0}
        blendColor={blendColor as string}
        customColor={color}
        elevation={disableElevation ? 0 : undefined}
      >
        <StyledComponents.Container maxWidth="lg">
          <div style={{ flex: 'auto', order: isMobile ? 2 : 1 }}>{BrandLogo}</div>
          <div style={{ order: isMobile ? 1 : 2 }}>
            <NavLinks />
          </div>
          <div style={{ order: 3 }}>
            <AuthLinks isMobile={isMobile} />
          </div>
        </StyledComponents.Container>
      </StyledComponents.Appbar>
      <Drawer
        open={isNavOpen}
        anchor={'top'}
        onClose={() => {
          setIsNavOpen(false);
        }}
        PaperProps={{
          sx: {
            marginTop: '60px',
          },
        }}
      >
        <DrawerMenuList
          clickHandler={() => {
            setIsNavOpen(false);
          }}
        />
        {!isLoggedIn && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '90%',
              margin: 'auto',
              justifyContent: 'center',
            }}
          >
            <StyledComponents.Button
              onClick={() => {
                loginWithRedirect({
                  appState: {
                    returnTo: APP_ROUTES.CUSTOMER_PORTAL.getRoute(),
                  },
                });
              }}
              variant="outlined"
              color="primary"
              fullWidth
              style={{ marginBottom: 20 }}
            >
              <p style={{ margin: '4px 0' }}>Login</p>
            </StyledComponents.Button>
          </div>
        )}
      </Drawer>
    </StyledComponents.HeaderWrapper>
  );
}
