import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';

export const StyledComponents = {
  Button: styled(Button)<ButtonProps>(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px !important',
    },
    padding: '6px 9px !important',
  })),
};
