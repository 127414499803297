/* eslint-disable camelcase */
export interface APIErrors {
  [x: string]: string;
}
export interface APIStatusMessage {
  status: boolean;
  message: string;
}

export interface StringsObject {
  [x: string]: string;
}

export interface ITrait {
  name: string;
  definition: string;
  type: string;
  scores: {
    [key: string]: {
      score: string;
      label: string;
      shortLabel: string;
    };
  };
  swot: string;
  commentary?: string;
  traitCategory: string | null;
  oneLiner?: string;
}

export enum ProfileType {
  Self = 'SELF',
  My_Child = 'MY_CHILD',
}

export type Report = {
  careerPaths?: CareerPath[];
  cpt: Cpt;
  professions: Professions;
  traits: ITrait[];
};

export type ReportData = {
  report: Report;
  reportConfiguration: ReportConfiguration;
};

export type Profession = {
  name: string;
  definition: string;
};

export type Professions = Profession[];

export type Cpt = {
  acronym: string;
  description: string;
  fullForm: string;
  strengths: string[];
  weaknesses: string[];
  toggleData: {
    possibleValues: string[];
    labels: string[];
    selectedValue: string;
    symbol: string;
  }[];
};

export type CareerPath = {
  name: string;
  info: { key: string; value: string }[];
};

export type Swot = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // TODO: Replace with actual type
};

export type AssessmentsConfig = {
  genomicsAssessment: {
    filter: {
      [x: string]: string[];
    };
    isActive: boolean;
  };
  astromancyAssessment: {
    filter: {
      [x: string]: string[];
    };
    isActive: boolean;
  };
  psychometricAssessment: {
    filter: {
      [x: string]: string[];
    };
    isActive: boolean;
  };
};

export type StartATourConfig = {
  isActive: boolean;
};

export type careerPathsConfig = {
  isActive: boolean;
};

export type oceanDimensionsConfig = {
  isActive: boolean;
  isTabHidden?: boolean;
  substeps: {
    cpt: {
      isActive: boolean;
      substeps: {
        psychometricAssessment: {
          isActive: boolean;
          filter: {
            [x: string]: string[];
          };
        };
      };
    };
    traits: {
      isActive: boolean;
      substeps: AssessmentsConfig;
    };
  };
};

export type PersonalityTraitsConfig = {
  isActive: boolean;
  isTabHidden?: boolean;
  substeps: {
    swot: {
      sub: {
        threats: {
          filter: {
            [x: string]: string[];
          };
          isActive: boolean;
        };
        strength: {
          filter: {
            [x: string]: string[];
          };
          isActive: boolean;
        };
        weaknesses: {
          filter: {
            [x: string]: string[];
          };
          isActive: boolean;
        };
        opportunities: {
          filter: {
            [x: string]: string[];
          };
          isActive: boolean;
        };
      };
      filter: {
        [x: string]: string[];
      };
      isActive: boolean;
    };
    details: {
      sub: {
        swot: {
          filter: {
            [x: string]: string[];
          };
          isActive: boolean;
        };
        genomicsAssessment: {
          filter: {
            [x: string]: string[];
          };
          isActive: boolean;
        };
        astromancyAssessment: {
          filter: {
            [x: string]: string[];
          };
          isActive: boolean;
        };
        psychometricAssessment: {
          filter: {
            [x: string]: string[];
          };
          isActive: boolean;
        };
      };
      filter: {
        sortBy: string[];
        traitCategory: string[];
        [x: string]: string[];
      };
      isActive: boolean;
    };
    atGlance: {
      sub: {
        genomicsAssessment: {
          filter: {
            [x: string]: string[];
          };
          isActive: boolean;
        };
        astromancyAssessment: {
          filter: {
            [x: string]: string[];
          };
          isActive: boolean;
        };
        psychometricAssessment: {
          filter: {
            [x: string]: string[];
          };
          isActive: boolean;
        };
      };
      filter: {
        [x: string]: string[];
      };
      isActive: boolean;
    };
  };
};

export type TalentAndAptitudeConfig = {
  filter: {
    sortBy: string[];
    traitCategory: string[];
    [x: string]: string[];
  };
  isActive: boolean;
  substeps: AssessmentsConfig;
};

export type TheGenDiscoverScienceConfig = {
  filter: {
    traitCategory: string[];
    [x: string]: string[];
  };
  isActive: boolean;
  substeps: {
    [x: string]: string;
  };
};

export type YourJourneyConfig = {
  builderPageSlug: string;
  sectionToHide: string[];
  isActive: boolean;
};

export type OurScienceConfig = {
  isActive: boolean;
  isTabHidden?: boolean;
  tabs: {
    name: string;
    builder_page_slug: string;
    isActive: boolean;
  }[];
};

export type ReportConfiguration = {
  startATour: StartATourConfig;
  careerPaths: careerPathsConfig;
  oceanDimensions: oceanDimensionsConfig;
  personalityTraits: PersonalityTraitsConfig;
  talentAndAptitude: TalentAndAptitudeConfig;
  theGenDiscoverScience: TheGenDiscoverScienceConfig;
  yourJourney: YourJourneyConfig;
  ourScience: OurScienceConfig;
};
