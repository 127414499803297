import { ReactElement } from 'react';
import Image from 'next/image';
import { Input } from '@builder.io/sdk/dist';
import { Container, Typography, Link as MUILink, Grid, Tooltip } from '@mui/material';
import { LocalPhone, MailOutline } from '@mui/icons-material';

import { StyledComponents } from './styled';
import { Box } from '@mui/system';

interface ISocialLinks {
  name: string;
  icon: string;
  link: string;
}

interface ITextLink {
  name: string;
  link: string;
}

export interface IFooterProps {
  address: string;
  phone: string;
  email: string;
  social: ISocialLinks[];
  links: ITextLink[];
  navigationSections: {
    title: string;
    links: ITextLink[];
  }[];
}

export const FooterProps: Input[] = [
  { name: 'address', type: 'string', required: true },
  { name: 'phone', type: 'string', required: true },
  { name: 'email', type: 'string', required: true },
  {
    name: 'social',
    type: 'list',
    required: true,
    subFields: [
      { name: 'name', type: 'string', required: true },
      { name: 'icon', type: 'string', required: true },
      { name: 'link', type: 'string', required: true },
    ],
  },
  {
    name: 'links',
    type: 'list',
    required: true,
    subFields: [
      { name: 'name', type: 'string', required: true },
      { name: 'link', type: 'string', required: true },
    ],
  },
];

/**
 * Renders Footer
 *
 * @param {IFooterProps} props
 * @return {ReactElement}
 */
export default function Footer({
  address,
  email,
  phone,
  social,
  links,
  navigationSections = [],
}: IFooterProps): ReactElement {
  return (
    <footer id="app_footer">
      <StyledComponents.Footer>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={6}
          >
            {navigationSections.map((section) => (
              <Grid
                key={section.title}
                item
                md={4}
                xs={12}
              >
                <Typography
                  variant="h6"
                  style={{ marginBottom: 12 }}
                >
                  {section.title}
                </Typography>
                {section.links.map((link, idx) => (
                  <div
                    key={idx}
                    style={{ marginBottom: 8 }}
                  >
                    <MUILink
                      href={link.link}
                      target="__blank"
                      style={{ textDecoration: 'none' }}
                    >
                      {link.name}
                    </MUILink>
                  </div>
                ))}
              </Grid>
            ))}
          </Grid>
          <StyledComponents.Root>
            <Grid
              container
              spacing={6}
            >
              <Grid
                item
                md={4}
                xs={12}
              >
                <div style={{ maxWidth: 200, marginBottom: 26 }}>
                  <Image
                    src={'/images/genleap-logo-white.png'}
                    alt="GENLEAP Logo"
                    width={200}
                    height={40}
                  />
                </div>
                <address>
                  <div
                    style={{ marginBottom: 16 }}
                    dangerouslySetInnerHTML={{ __html: address }}
                  ></div>
                  <Typography
                    variant="body2"
                    style={{ fontFamily: 'Montserrat', marginBottom: 16 }}
                  >
                    <LocalPhone style={{ marginRight: 16 }} /> {phone}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ fontFamily: 'Montserrat' }}
                  >
                    <MailOutline style={{ marginRight: 16 }} /> {email}
                  </Typography>
                </address>
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
              ></Grid>
              <Grid
                item
                md={4}
                xs={12}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignContent="space-between"
                >
                  <Typography
                    variant="h6"
                    style={{ marginBottom: 12 }}
                  >
                    Need help?
                  </Typography>
                  {links.map((link, idx) => (
                    <div
                      key={idx}
                      style={{ marginBottom: 8 }}
                    >
                      <MUILink
                        href={link.link}
                        target="__blank"
                      >
                        {link.name}
                      </MUILink>
                    </div>
                  ))}
                  <Typography
                    variant="h6"
                    style={{ marginBottom: 12, marginTop: 28 }}
                  >
                    Follow Us
                  </Typography>
                  <StyledComponents.SocialIconsGroup>
                    {social.map((socialMedia, idx) => {
                      return (
                        <StyledComponents.IconWrapper key={idx}>
                          <Tooltip title={socialMedia.name}>
                            <MUILink
                              href={socialMedia.link}
                              target="__blank"
                            >
                              <Image
                                src={socialMedia.icon}
                                height={35}
                                width={35}
                                alt={socialMedia.name}
                              />
                            </MUILink>
                          </Tooltip>
                        </StyledComponents.IconWrapper>
                      );
                    })}
                  </StyledComponents.SocialIconsGroup>
                </Box>
              </Grid>
            </Grid>
          </StyledComponents.Root>
        </Container>
      </StyledComponents.Footer>
    </footer>
  );
}
