import React from 'react';
import { useRouter } from 'next/router';
import { useAuth0 } from '@auth0/auth0-react';
import { DashboardOutlined, Logout, SupervisedUserCircleOutlined } from '@mui/icons-material';
import { ButtonProps, IconButton, Menu, MenuItem, Divider, ListItemIcon, Typography, Avatar } from '@mui/material';

import { APP_ROUTES } from 'constants/routes';
import { getConfig } from 'config';
import { StyledComponents } from './styled';

type Props = ButtonProps & {
  returnToSamePage?: boolean;
  isMobile?: boolean;
};

/**
 * Renders Auth Links
 * @param {Props} props
 * @return {ReactElement}
 */
export default function AuthLinks(props: Props) {
  const { loginWithRedirect, logout, user, isAuthenticated, isLoading } = useAuth0();
  const router = useRouter();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleIconButtonClick = ({ currentTarget }: { currentTarget: HTMLButtonElement }) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    logout({ returnTo: getConfig().AUTH0_APP_BASE_URL });
  };

  if (isLoading) return null;

  const renderAuthLinks = () => {
    if (!user || !isAuthenticated) {
      return (
        <>
          {!props.isMobile ? (
            <StyledComponents.Button
              onClick={() => {
                loginWithRedirect({
                  appState: {
                    returnTo: APP_ROUTES.CUSTOMER_PORTAL.getRoute(),
                  },
                });
              }}
              variant="outlined"
              color="white"
              style={{ marginRight: 10 }}
              {...props}
            >
              Login
            </StyledComponents.Button>
          ) : null}
          <StyledComponents.Button
            onClick={() => {
              loginWithRedirect({
                screen_hint: 'signup',
                appState: {
                  returnTo: APP_ROUTES.PRICING_PAGE.getRoute(),
                },
              });
            }}
            variant="contained"
            color="primary"
            {...props}
          >
            ORDER
          </StyledComponents.Button>
        </>
      );
    }

    return (
      <>
        <IconButton
          onClick={handleIconButtonClick}
          size="small"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar
            src={user.picture}
            sx={{ width: '42px', height: '42px' }}
            alt="profile picture"
          />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          style={{ zIndex: '23000' }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              minWidth: '220px',
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div>
              <Avatar
                src={user?.picture}
                sx={{ width: '55px', height: '55px' }}
                alt="profile picture"
              />
            </div>
            <Typography
              style={{
                fontSize: '12px',
                color: 'grey',
                margin: '5px 0px 10px 0px',
              }}
            >
              {user?.email}
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              router.push(APP_ROUTES.CUSTOMER_PORTAL.getRoute());
            }}
          >
            <ListItemIcon>
              <DashboardOutlined fontSize="small" />
            </ListItemIcon>
            Dashboard
          </MenuItem>
          <MenuItem
            onClick={() => {
              router.push(APP_ROUTES.CP_SIGNUP_PROFILE.getRoute());
            }}
          >
            <ListItemIcon>
              <SupervisedUserCircleOutlined fontSize="small" />
            </ListItemIcon>
            Your Account
          </MenuItem>
          <MenuItem onClick={handleSignOut}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Sign Out
          </MenuItem>
        </Menu>
      </>
    );
  };

  return <div>{renderAuthLinks()}</div>;
}
