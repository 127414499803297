export const APP_ROUTES = {
  // Customer Portal Routes
  CUSTOMER_PORTAL: {
    getRoute: () => '/customer/ordered-product/',
  },
  CP_SIGNUP_PROFILE: {
    getRoute: () => '/customer/your-account',
  },
  CP_FREEMIUM_PRODUCT: {
    getRoute: () => '/customer/personality-sampler',
  },
  CP_FREEMIUM_PRODUCT_CALL_SCREEN: {
    getRoute: () => '/customer/personality-sampler/call-screen',
  },
  CP_FREEMIUM_PRODUCT_ENDING_SCREEN: {
    getRoute: () => '/customer/personality-sampler/ending-screen',
  },
  CP_ORDER_FULFILLMENT_STEPS: {
    getRoute: (params: { opID: string }) => {
      return `/customer/ordered-product/${params.opID}/order-fulfillment/steps/`;
    },
  },
  CP_ADD_ADDRESS_DETAILS: {
    getRoute: (params: { opID: string; subStepStatus: string; stepId?: string; subStepId?: string }) => {
      const query = new URLSearchParams();
      query.set('status', params.subStepStatus);
      query.set('stepId', params.stepId ?? '');
      query.set('subStepId', params.subStepId ?? '');
      return `/customer/ordered-product/${params.opID}/order-fulfillment/address-details/?${query.toString()}`;
    },
  },
  CP_ADD_USER_PROFILE: {
    getRoute: (params: { opID: string; subStepStatus: string; stepId?: string; subStepId?: string }) => {
      const query = new URLSearchParams();
      query.set('status', params.subStepStatus);
      query.set('stepId', params.stepId ?? '');
      query.set('subStepId', params.subStepId ?? '');
      return `/customer/ordered-product/${params.opID}/order-fulfillment/profile-details/?${query.toString()}`;
    },
  },
  CP_ADD_BIRTH_DETAILS: {
    getRoute: (params: { opID: string; subStepStatus: string; stepId?: string; subStepId?: string }) => {
      const query = new URLSearchParams();
      query.set('status', params.subStepStatus);
      query.set('stepId', params.stepId ?? '');
      query.set('subStepId', params.subStepId ?? '');
      return `/customer/ordered-product/${params.opID}/order-fulfillment/birth-details/?${query.toString()}`;
    },
  },
  CP_INITIATE_DNA_SAMPLE_PICKUP: {
    getRoute: (params: { opID: string; subStepStatus: string; stepId?: string; subStepId?: string }) => {
      const query = new URLSearchParams();
      query.set('status', params.subStepStatus);
      query.set('stepId', params.stepId ?? '');
      query.set('subStepId', params.subStepId ?? '');
      return `/customer/ordered-product/${params.opID}/order-fulfillment/dna-sample-pickup/?${query.toString()}`;
    },
  },
  CP_PSY_INSTRUCTIONS: {
    getRoute: (params: { opID: string; subStepStatus: string; stepId?: string; subStepId?: string }) => {
      const query = new URLSearchParams();
      query.set('status', params.subStepStatus);
      query.set('stepId', params.stepId ?? '');
      query.set('subStepId', params.subStepId ?? '');
      return `/customer/ordered-product/${
        params.opID
      }/order-fulfillment/psychometric-assessment/instructions/?${query.toString()}`;
    },
  },
  CP_PSY_ASSESSMENT: {
    getRoute: (params: { opID: string; subStepStatus: string; stepId?: string; subStepId?: string }) => {
      const query = new URLSearchParams();
      query.set('status', params.subStepStatus);
      query.set('stepId', params.stepId || '');
      query.set('subStepId', params.subStepId || '');
      return `/customer/ordered-product/${params.opID}/order-fulfillment/psychometric-assessment/?${query.toString()}`;
    },
  },
  CP_PSY_ASSESSMENT_FINISH: {
    getRoute: (params: { opID: string }) => {
      return `/customer/ordered-product/${params.opID}/order-fulfillment/psychometric-assessment/finish/`;
    },
  },

  // Discovery Report Routes
  DISCOVERY_REPORT: {
    getRoute: (params: { opID: string; subStepStatus: string }) => {
      const query = new URLSearchParams();
      query.set('status', params.subStepStatus);
      return `/customer/ordered-product/${params.opID}/discovery-report/?${query.toString()}`;
    },
  },
  PERSONALITY_SAMPLER_REPORT: {
    getRoute: (params: { opID: string }) => {
      return `/customer/ordered-product/${params.opID}/discovery-report/personality-sampler-report`;
    },
  },
  DR_GENDISCOVER_SCIENCE: {
    getRoute: (params: { opID: string }) =>
      `/customer/ordered-product/${params.opID}/discovery-report/gendiscover-science/`,
  },
  DR_OCEAN_DIMENSIONS: {
    getRoute: (params: { opID: string }) => {
      return `/customer/ordered-product/${params.opID}/discovery-report/ocean-dimensions/`;
    },
  },
  DR_CORE_PERSONALITY_TYPE: {
    getRoute: (params: { opID: string }) =>
      `/customer/ordered-product/${params.opID}/discovery-report/personality/core-type/`,
  },
  DR_PERSONALITY_TRAITS: {
    getRoute: (params: { opID: string }) =>
      `/customer/ordered-product/${params.opID}/discovery-report/personality/traits/`,
  },
  DR_TALENT_AND_APTITUDE: {
    getRoute: (params: { opID: string }) =>
      `/customer/ordered-product/${params.opID}/discovery-report/talent-and-aptitude/`,
  },
  DR_PROFESSIONAL_PERSONAS: {
    getRoute: (params: { opID: string }) =>
      `/customer/ordered-product/${params.opID}/discovery-report/professional-personas/`,
  },
  DR_CAREER_PATHS: {
    getRoute: (params: { opID: string }) => `/customer/ordered-product/${params.opID}/discovery-report/career-paths/`,
  },
  DR_YOUR_JOURNEY: {
    getRoute: (params: { opID: string }) => `/customer/ordered-product/${params.opID}/discovery-report/your-journey/`,
  },

  DR_OUR_SCIENCE: {
    getRoute: (params: { opID: string }) => `/customer/ordered-product/${params.opID}/discovery-report/our-science/`,
  },
  PRODUCTS: {
    getRoute: () => '/pricing',
  },
  ORDER_SUMMARY: {
    getRoute: () => '/checkout/order-summary',
  },
  ORDER_CALLBACK: {
    getRoute: (params: { status: string; txnId: string; txnAmount: string }) => {
      const query = new URLSearchParams();
      query.set('status', params.status);
      query.set('txn_id', params.txnId);
      query.set('txn_amount', params.txnAmount);
      return '/checkout/payment-callback/?' + query.toString();
    },
  },

  BOOK_SESSION: {
    getRoute: (params: {
      opID: string;
      sessionName?: string;
      type: string;
      stepId: string;
      subStepId: string;
      aspirantProfileId: string;
    }) => {
      return `/customer/ordered-product/${params.opID}/order-fulfillment/book-session/?type=${params.type}&sessionName=${params.sessionName}&stepId=${params.stepId}&subStepId=${params.subStepId}&aspirantProfileId=${params.aspirantProfileId}`;
    },
  },
  FREE_SESSION: {
    getRoute: (token: string) => `/customer/free-session?token=${token}`,
  },
  PRICING_PAGE: {
    getRoute: () => '/pricing',
  },
};
